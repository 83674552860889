@media screen and (min-width: 1025px) {
    #info-card {
        max-width: calc(7*5vw);
    }

    #contact-form {
        max-width: calc(10 * 5vw);
        width: calc(10 * 5vw);
    }

    #contact-form .btn {
        font-size: calc(1.3*1vw);
    }
} 

#footer {
    height: 25px;
}

.h2-underline {
    text-decoration: underline dotted;
    text-decoration-thickness: 0.4rem;
}

#contact-box a {
    text-decoration: none;
}

#info-card {
    margin-top: calc(1.2*1rem);
    padding: 15px;
    background-color: rgb(172, 204, 214);
}

#contact-form {
    margin: 15px 0;
}

#contact-form .btn {
    margin: 15px 0;
}

#contact-box {
    background-color:#04050e65;
    padding: 15px;
    border-radius: 2%;
    color: whitesmoke;
}

#contact-box a, #contact-box, #contact-box h2 {
    color: whitesmoke;
}

#contact-box label {
    color: #04050e;
}